import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["templateSelect", "messageContent", "messageContainer"];
  static values = { phone: String };

  updateMessageContent() {
    const selectedContent = this.templateSelectTarget.value;
    this.messageContentTarget.value = selectedContent;
    this.messageContainerTarget.style.display = selectedContent
      ? "block"
      : "none";
  }

  sendMessage() {
    const message = this.messageContentTarget.value;
    const phoneNumber = this.formatPhoneNumber(this.phoneValue);
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    window.location.assign(whatsappURL);
  }

  formatPhoneNumber(number) {
    return number.replace(/[\s\-\(\)]+/g, "").replace(/(\+\d{1})(\d+)/, "$1$2");
  }
}
