import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  search(event) {
    const query = event.target.value;
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      const busyTimeout = setTimeout(() => {
        event.target
          .closest(".combobox-wrapper")
          .setAttribute("aria-busy", "true");
      }, 100);

      fetch(`/agenda/busca_cliente?query=${encodeURIComponent(query)}`, {
        headers: { Accept: "text/vnd.turbo-stream.html" },
      })
        .then((r) => r.text())
        .then((html) => Turbo.renderStreamMessage(html))
        .then(() => {
          clearTimeout(busyTimeout);
          event.target
            .closest(".combobox-wrapper")
            .removeAttribute("aria-busy");
        });
    }, 300);
  }
}
