import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.frameRenderHandler = this.frameRender.bind(this);
    document.addEventListener("turbo:frame-render", this.frameRenderHandler);
    document.addEventListener("turbo:before-cache", this.beforeCache);
  }

  disconnect() {
    document.removeEventListener("turbo:frame-render", this.frameRenderHandler);
    document.removeEventListener("turbo:before-cache", this.beforeCache);
  }

  frameRender(event) {
    if (event.target.id !== "service_form") return;

    document.querySelector("#new-service").classList.add("disabled");
  }

  beforeCache(event) {
    document.querySelector("#service_form").innerHTML =
      "<div class='-mb-5'></div>";
  }
}
