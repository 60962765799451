import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["editButton"];

  connect() {
    this.updateFormInputs();
  }

  toggleEditable() {
    const isEditable = this.element.dataset.editable === "true";
    this.element.dataset.editable = String(!isEditable);

    this.updateFormInputs();
  }

  updateFormInputs() {
    this.element
      .querySelector("form")
      .querySelectorAll("input")
      .forEach((input) => {
        input.disabled = this.element.dataset.editable === "false";
      });
  }
}
