import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "dialog"];

  connect() {
    if (!this.hasRecurrenceIndex()) return;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.formTarget.addEventListener("submit", this.handleSubmit);
    this.initialState = Object.fromEntries(
      Object.entries(this.getRecurrenceAttributes()).filter(
        // These attributes are removed on form initialization, so we need to ignore them
        ([key]) => !key.startsWith("appointment[service_items_attributes][0]")
      )
    );
  }

  disconnect() {
    if (!this.hasRecurrenceIndex()) return;
    this.formTarget.removeEventListener("submit", this.handleSubmit);
  }

  hasRecurrenceIndex() {
    return !!this.formTarget.querySelector("#appointment_recurrence_index")
      ?.value;
  }

  getRecurrenceAttributes() {
    const formData = new FormData(this.formTarget);
    const relevantFields = [
      "appointment[starts_at]",
      "appointment[ends_at]",
      "appointment[notify_customer]",
      "appointment[service_items_attributes]",
    ];

    return Object.fromEntries(
      Array.from(formData.entries()).filter(([key]) =>
        relevantFields.some((field) => key.startsWith(field))
      )
    );
  }

  hasRecurrenceAttributesChanged() {
    const currentState = this.getRecurrenceAttributes();
    return (
      JSON.stringify(currentState).replace(/\s/g, " ") !==
      JSON.stringify(this.initialState).replace(/\s/g, " ")
    );
  }

  handleSubmit(event) {
    if (this.skipDialog) return (this.skipDialog = false);
    if (!this.hasRecurrenceIndex()) return;

    const form = event.target;
    const hasRecurrenceGroupId = !!form.querySelector(
      "#appointment_recurrence_group_id"
    )?.value;
    const hasRecurrence = !!form.querySelector("#appointment_recurrence")
      ?.value;

    if (!hasRecurrenceGroupId && !hasRecurrence) return;
    if (!this.hasRecurrenceAttributesChanged()) return;

    event.preventDefault();
    this.dialogTarget.firstElementChild.setAttribute("data-state", "opened");
  }

  submit(event) {
    event.preventDefault();
    const form = this.formTarget;

    let updateModeField = form.querySelector("#appointment_update_mode");
    if (!updateModeField) {
      updateModeField = document.createElement("input");
      updateModeField.type = "hidden";
      updateModeField.id = "appointment_update_mode";
      updateModeField.name = "appointment[update_mode]";
      form.appendChild(updateModeField);
    }
    updateModeField.value = event.target.getAttribute("data-mode");

    this.dialogTarget.firstElementChild.setAttribute("data-state", "closed");
    this.skipDialog = true;
    form.requestSubmit();
  }

  close() {
    this.dialogTarget.firstElementChild.setAttribute("data-state", "closed");
  }
}
