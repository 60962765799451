import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { mask: String };

  connect() {
    this.handleInput();
    this.inputHandler = this.handleInput.bind(this);
    this.keyDownHandler = this.handleMoneyInput.bind(this);
    this.element.addEventListener("input", this.inputHandler);
    this.element.addEventListener("keydown", this.keyDownHandler);
  }

  disconnect() {
    this.element.removeEventListener("input", this.inputHandler);
    this.element.removeEventListener("keydown", this.keyDownHandler);
  }

  handleInput(event) {
    let selectionStart = this.element.selectionStart || 0;
    let oldValue = this.element.value;
    let newValue = this.applyMask(oldValue, this.maskValue);
    this.element.value = newValue;
    let caret = selectionStart;
    if (this.maskValue !== "money") caret += newValue.length - oldValue.length;

    if (caret < 0) caret = 0;
    if (caret > newValue.length) caret = newValue.length;
    this.element.setSelectionRange(caret, caret);
  }

  applyMask(value, mask) {
    if (mask === "money") return this.formatCurrency(value);

    if (!mask) return value;

    let result = "";
    let numbers = value.match(/\d/g) || [];
    let valueIndex = 0;

    for (let i = 0; i < mask.length; i++) {
      if (numbers.length == 0 && valueIndex >= value.length) break;

      if (mask[i] === "9") {
        if (/\d/.test(value[valueIndex])) {
          result += value[valueIndex];
          numbers = numbers.slice(1);
        } else {
          i--;
        }
        valueIndex++;
      } else {
        if (value[valueIndex] === mask[i]) {
          result += mask[i];
          valueIndex++;
        } else {
          result += mask[i];
        }
      }
    }

    return result;
  }

  formatCurrency(value) {
    const number = parseFloat(value.replace(/[^0-9,]/g, "").replace(",", "."));
    return (number || 0).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }

  handleMoneyInput(event) {
    if (!event || this.maskValue !== "money") return;

    if (event.target.value.indexOf("R$ 0,00") !== -1 && /\d/.test(event.key)) {
      this.element.setSelectionRange(3, 3);
    } else if (this.newCursorPosition(event) < 4) {
      this.element.setSelectionRange(4, 4);
    }

    if (event.key !== "Backspace") return;

    const caretPosition =
      this.element.value.length - this.element.selectionStart;
    if (caretPosition !== 2) return;

    event.preventDefault();

    this.element.setSelectionRange(
      this.element.selectionStart - 1,
      this.element.selectionStart - 1
    );

    return true;
  }

  newCursorPosition(event) {
    if (!event) return;

    let newPosition = this.element.selectionStart;

    if (event.key === "ArrowLeft") {
      newPosition = Math.max(0, newPosition - 1);
    } else if (event.key === "ArrowRight") {
      newPosition = Math.min(this.element.value.length, newPosition + 1);
    } else if (event.key === "ArrowUp") {
      newPosition = 0;
    } else if (event.key === "ArrowDown") {
      newPosition = this.element.value.length;
    } else if (event.key === "Backspace") {
      newPosition = Math.max(0, newPosition - 1);
    } else if (event.key === "Delete") {
      newPosition = Math.min(this.element.value.length, newPosition + 1);
    } else {
      newPosition = newPosition + 1;
    }

    return newPosition;
  }
}
