import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.initializing = true;
    this.handleFrameLoad = this.handleFrameLoad.bind(this);
  }

  connect() {
    if (document.body.classList.contains("native")) return;

    this.element.addEventListener("turbo:frame-load", this.handleFrameLoad);
  }

  disconnect() {
    this.element.removeEventListener("turbo:frame-load", this.handleFrameLoad);
  }

  handleFrameLoad(event) {
    if (this.initializing) return (this.initializing = false);

    const frame = event.target;
    if (frame.id !== "customer-list") return;

    const frameUrl = new URL(frame.src);
    const newUrl = new URL(window.location);

    newUrl.pathname = newUrl.pathname.replace("/list", "");
    newUrl.search = frameUrl.search;

    history.replaceState({}, "", newUrl.toString());
  }
}
