import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["priceField"];

  connect() {
    const newServiceItemTemplate = document.querySelector(
      "#new-service-item-template"
    );
    document.body.appendChild(newServiceItemTemplate);
  }

  remove(event) {
    const serviceItem = event.target.closest(".service-item");
    const destroyInput = serviceItem.querySelector('input[name*="_destroy"]');
    const index = destroyInput.id.match(/\d+/)[0];
    const idInput = document.querySelector(
      `#appointment_service_items_attributes_${index}_id`
    );
    if (!idInput) return serviceItem.remove();

    destroyInput.value = true;
    serviceItem.classList.add("hidden");
  }

  duplicate(event) {
    event.preventDefault();
    const newItem = document
      .querySelector("#new-service-item-template")
      .cloneNode(true);
    newItem.id = "";
    newItem.classList.add("animate-in");
    newItem.classList.remove("hidden");
    newItem.querySelector('input[name*="id"]')?.remove();
    this.fixNewGroupIndex(newItem);
    const container = document.querySelector(".service-items-container");
    container.appendChild(newItem);
    newItem.querySelector('input[name*="name"]').focus();
  }

  onServiceChange(event) {
    const selectedValue = event.target.dataset.comboboxValue;
    const serviceTemplate = document.querySelector(
      `.service-template[data-service-name="${selectedValue}"]`
    );

    if (!serviceTemplate) return;

    const price = serviceTemplate.dataset.servicePrice;
    if (!price) return;

    const formattedPrice = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(price);
    this.priceFieldTarget.value = formattedPrice;
  }

  fixNewGroupIndex(newItem) {
    const newIndex = this.maxIndex() + 1;
    newItem.querySelectorAll("input").forEach((input) => {
      input.id = input.id.replace(
        /appointment_service_items_attributes_\d+/,
        `appointment_service_items_attributes_${newIndex}`
      );
      input.name = input.name.replace(
        /\[service_items_attributes\]\[\d+\]/,
        `[service_items_attributes][${newIndex}]`
      );
    });

    newItem.querySelectorAll("label").forEach((label) => {
      label.htmlFor = label.htmlFor.replace(
        /appointment_service_items_attributes_\d+/,
        `appointment_service_items_attributes_${newIndex}`
      );
    });
  }

  maxIndex() {
    const container = document.querySelector(".service-items-container");
    const serviceItems = container.querySelectorAll(
      ".service-item input[name*='[service_items_attributes]']"
    );
    let maxIndex = -1;

    serviceItems.forEach((input) => {
      const match = input.name.match(/\[service_items_attributes\]\[(\d+)\]/);
      if (match) {
        const index = parseInt(match[1], 10);
        if (index > maxIndex) {
          maxIndex = index;
        }
      }
    });

    return maxIndex;
  }
}
