import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.initializing = true;
    this.handleFrameLoad = this.handleFrameLoad.bind(this);
  }

  connect() {
    this.prefillCustomer();

    if (document.body.classList.contains("native")) return;

    this.element.addEventListener("turbo:frame-load", this.handleFrameLoad);
  }

  disconnect() {
    this.element.removeEventListener("turbo:frame-load", this.handleFrameLoad);
  }

  prefillCustomer() {
    const url = new URL(window.location);
    const prefillCustomerId = url.searchParams.get("prefill_customer_id");
    if (!prefillCustomerId) return;

    const createAppointmentBtn = document.getElementById(
      "create-appointment-button"
    );
    const createAppointmentLink = createAppointmentBtn.closest("a");
    Turbo.visit(createAppointmentLink.href, { frame: "appointment_dialog" });
  }

  handleFrameLoad(event) {
    if (this.initializing) return (this.initializing = false);

    const frame = event.target;
    if (frame.id !== "calendar") return;

    const frameUrl = new URL(frame.src);
    const newUrl = new URL(window.location);

    newUrl.pathname = newUrl.pathname.replace("/calendar", "");
    newUrl.search = frameUrl.search;

    history.replaceState({}, "", newUrl.toString());
  }
}
