import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dialog", "content", "trigger"];
  static values = { openAutomatically: String };

  initialize() {
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.observeContentChanges = this.observeContentChanges.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.touchStartY = 0;
    this.touchDeltaY = 0;
    this.initialScrollTop = 0;
    this.isDragging = false;
    this.isScrolling = false;
    this.parentDialog = null;
  }

  connect() {
    if (!this.hasDialogTarget || !this.hasContentTarget) return;

    if (this.dialogTarget.dataset.state === "opened") {
      this.open();
    }

    this.contentObserver = new MutationObserver(this.observeContentChanges);
    this.contentObserver.observe(this.contentTarget, {
      childList: true,
      subtree: true,
    });
  }

  disconnect() {
    if (!this.hasDialogTarget) return;
    this.close();

    if (this.contentObserver) {
      this.contentObserver.disconnect();
    }
  }

  handleTouchStart(event) {
    if (!event.touches[0]) return;
    this.touchStartY = event.touches[0].clientY;
    this.initialScrollTop = this.contentTarget.scrollTop;
    this.isDragging = false;
    this.isScrolling = false;
  }

  handleTouchMove(event) {
    if (!event.touches[0]) return;

    const currentY = event.touches[0].clientY;
    this.touchDeltaY = currentY - this.touchStartY;

    // Determine if we're scrolling or dragging to dismiss
    if (!this.isDragging && !this.isScrolling) {
      // If we're at the top and moving down, we're dragging
      if (this.contentTarget.scrollTop <= 0 && this.touchDeltaY > 0) {
        this.isDragging = true;
        this.contentTarget.style.transition = "none";
      } else {
        this.isScrolling = true;
        return; // Let the browser handle the scroll
      }
    }

    // If we're scrolling, let the browser handle it
    if (this.isScrolling) return;

    // If we're dragging, handle the dismissal gesture
    if (this.isDragging) {
      event.preventDefault();
      const dampedDelta = Math.pow(this.touchDeltaY, 0.7);
      const opacity = Math.max(0, 1 - dampedDelta / 400);
      this.dialogTarget.style.backgroundColor = `rgba(0, 0, 0, ${
        opacity * 0.5
      })`;
      this.contentTarget.style.transform = `translateY(${dampedDelta}px)`;
    }
  }

  handleTouchEnd() {
    if (!this.isDragging && !this.isScrolling) return;

    if (this.isDragging) {
      this.contentTarget.style.transition = "";

      if (this.touchDeltaY > 200) {
        this.close();
      } else {
        this.contentTarget.style.transform = "";
        this.dialogTarget.style.backgroundColor = "";
      }
    }

    this.isDragging = false;
    this.isScrolling = false;
  }

  handleStateChange() {
    const state = this.dialogTarget.dataset.state;
    if (state === "opened") {
      this.open();
    } else if (state === "closed") {
      this.close();
    }
  }

  open() {
    const openedDialogContent = document.querySelector(
      '[data-state="opened"] [data-dialog-target="content"]'
    );

    if (openedDialogContent) {
      this.parentDialog = openedDialogContent;
      this.lockParentScroll();
    }

    this.dialogTarget.dataset.state = "opened";
    this.dialogTarget.setAttribute("aria-hidden", "false");
    document.addEventListener("keydown", this.handleKeyDown);
    this.focusableElements = this.dialogTarget.querySelectorAll(
      "button, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])"
    );
    this.firstFocusableElement = this.focusableElements[0];
    this.lastFocusableElement =
      this.focusableElements[this.focusableElements.length - 1];
    this.firstFocusableElement.focus();

    if (window.innerWidth < 768 && openedDialogContent) {
      setTimeout(() => {
        openedDialogContent.scrollTop = 0;
      }, 10);
    }
  }

  close() {
    this.dialogTarget.dataset.state = "closed";
    this.dialogTarget.setAttribute("aria-hidden", "true");
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("focusin", this.handleFocusTrap);

    if (this.parentDialog) {
      this.unlockParentScroll();
      this.parentDialog = null;
    }

    if (this.hasTriggerTarget) this.triggerTarget.focus();
    this.contentTarget.style.transform = `translateY(0px)`;
  }

  closeOnClick(event) {
    event.preventDefault();
    this.close();
  }

  handleKeyDown(event) {
    if (event.key === "Escape") {
      const dialogs = document.querySelectorAll(
        '[data-dialog-target="dialog"]'
      );
      const topDialog = Array.from(dialogs)
        .reverse()
        .find((dialog) => dialog.dataset.state === "opened");
      if (topDialog === this.dialogTarget) {
        this.close();
      }
    }

    if (event.key !== "Tab") return;

    if (this.focusableElements.length === 0) {
      event.preventDefault();
      return;
    }

    if (event.shiftKey) {
      if (document.activeElement !== this.firstFocusableElement) return;

      event.preventDefault();
      this.lastFocusableElement.focus();
    } else {
      if (document.activeElement !== this.lastFocusableElement) return;

      event.preventDefault();
      this.firstFocusableElement.focus();
    }
  }

  handleBackdropClick(event) {
    if (event.target !== this.dialogTarget) return;

    this.close();
  }

  observeContentChanges(mutations) {
    mutations.forEach((_mutation) => {
      if (
        !this.openAutomaticallyValue ||
        document.querySelector("html").hasAttribute("data-turbo-preview") ||
        this.dialogTarget.dataset.state === "opened"
      )
        return;

      const hasNonEmptyChild = Array.from(
        this.contentTarget.querySelectorAll("*")
      ).some((child) => child.textContent.trim() !== "");
      if (!hasNonEmptyChild) return;

      this.open();
    });
  }

  lockParentScroll() {
    if (this.parentDialog) {
      this.parentDialog.style.overflow = "hidden";
    }
  }

  unlockParentScroll() {
    if (this.parentDialog) {
      this.parentDialog.style.overflow = "";
    }
  }
}
