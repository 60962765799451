import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";

export default class extends Controller {
  connect() {
    this.frame = this.element.closest("turbo-frame");
    if (!this.shouldInterceptTurbo()) return;

    this.frameMissingHandler = this.frameMissing.bind(this);
    this.frameRenderHandler = this.frameRender.bind(this);
    this.submitEndHandler = this.submitEnd.bind(this);

    this.frame.addEventListener("turbo:frame-render", this.frameRenderHandler);
    this.frame.addEventListener(
      "turbo:frame-missing",
      this.frameMissingHandler
    );
    this.element.addEventListener("turbo:submit-end", this.submitEndHandler);
  }

  disconnect() {
    if (!this.shouldInterceptTurbo()) return;

    this.frame.removeEventListener(
      "turbo:frame-render",
      this.frameRenderHandler
    );
    this.frame.removeEventListener(
      "turbo:frame-missing",
      this.frameMissingHandler
    );
    this.element.removeEventListener("turbo:submit-end", this.submitEndHandler);
  }

  frameMissing(event) {
    if (!event.detail.response.redirected) return;

    event.preventDefault();
    const action = this.frame.getAttribute("action");
    Turbo.visit(event.detail.response.url, {
      action: action || "advance",
    });
  }

  submitEnd(_event) {
    this.element
      .querySelectorAll('button, input[type="submit"]')
      .forEach((element) => (element.disabled = true));
  }

  frameRender(event) {
    const redirectUrl = event.detail.fetchResponse.response.url;
    this.renderToast(redirectUrl);
  }

  shouldInterceptTurbo() {
    return this.frame && this.element.dataset.turboFrame !== "_top";
  }

  renderToast(url) {
    const urlParams = new URLSearchParams(url.split("?")[1]);
    const toastTypes = ["success", "notice", "error", "warning"];

    toastTypes.forEach((type) => {
      if (urlParams.has(type)) {
        this.toast(urlParams.get(type), type);
      }
    });
  }

  toast(title, type) {
    const toast = document.createElement("div");
    toast.classList.add("toast-notification");
    toast.dataset.type = type;
    toast.dataset.controller = "toast";
    toast.innerHTML = `
      <div data-controller="toast" data-state="opened">
        <div class="flex flex-col items-start gap-1">
          <span class="toast-description"><p>${title}</p></span>
        </div>
        <button class="toast-icon !p-0 !right-[14px] !top-[3px] text-2xl font-light" data-action="click->toast#close">&times;</button>
      </div>
    `;
    document.body.appendChild(toast);
  }
}
