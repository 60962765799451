import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    earliestTime: String,
  };

  connect() {
    this.scrollContainer = this.element.querySelector("#calendar-appointments");
    if (!this.scrollContainer) return;

    const url = new URL(window.location);
    if (url.searchParams.has("appointment_id")) {
      this.resetScrollFromPreviousValue();
      return this.scrollToAppointment();
    }

    if (window.calendarScrollPosition !== undefined) {
      return this.resetScrollFromPreviousValue();
    }

    if (!this.earliestTimeValue) {
      const nineOClockElement = this.element.querySelector(`[data-hour="9"]`);
      nineOClockElement.scrollIntoView({ behavior: "instant", block: "start" });
      return;
    }

    const [hours, _minutes] = this.earliestTimeValue.split(":").map(Number);
    const hourElement = this.element.querySelector(`[data-hour="${hours}"]`);

    if (!hourElement) return;

    hourElement.scrollIntoView({ behavior: "instant", block: "start" });
  }

  resetScrollFromPreviousValue() {
    if (window.calendarScrollPosition === undefined) return;

    this.scrollContainer.scrollTop = window.calendarScrollPosition;
  }

  scrollToAppointment() {
    const url = new URL(window.location);
    const appointmentId = url.searchParams.get("appointment_id");
    const appointmentElement = document.querySelector(
      `#appointment-${appointmentId}`
    );
    if (!appointmentElement) return;

    setTimeout(() => {
      appointmentElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 1);
  }

  handleScroll(event) {
    window.calendarScrollPosition = event.target.scrollTop;
  }
}
