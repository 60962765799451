import "@hotwired/turbo-rails";
import "../controllers";
import "../channels";
import "../action_cable";
import { init as SentryInit } from "@sentry/browser";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/service_worker.js");
}

SentryInit({
  dsn: window._sentry_dsn,
  environment: window._env,
  integrations: [],
  tracesSampleRate: 0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});

document.addEventListener("turbo:visit", () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  document.cookie = `time_zone=${timeZone}; path=/`;
});

document.addEventListener("turbo:before-fetch-request", (event) => {
  if (event.target.tagName !== "TURBO-FRAME") return;

  event.target.setAttribute("turbo-busy", "true");
});

document.addEventListener("turbo:before-frame-render", (event) => {
  if (event.target.tagName !== "TURBO-FRAME") return;

  event.target.removeAttribute("turbo-busy");
});

document.addEventListener("turbo:before-cache", (event) => {
  resetForms();
  clearDialogTurboFrame();
});

function resetForms() {
  document.querySelectorAll(".error-message-wrapper").forEach((element) => {
    element.remove();
  });

  document.querySelectorAll(".field_with_errors").forEach((element) => {
    Array.from(element.children).forEach((child) => {
      removeErrorClasses(child);
    });
  });

  document.body.querySelectorAll("form").forEach((form) => {
    form.reset();
  });
}

// Clear data populated by turbo-frame for the dialogs that open automatically when data is loaded to avoid
// opening dialog with old data when navigating the browser history stack
function clearDialogTurboFrame() {
  const dialogElements = document.querySelectorAll(
    '[data-controller="dialog"][data-dialog-open-automatically-value="true"]'
  );
  dialogElements.forEach((dialogElement) => {
    const turboFrames = dialogElement.querySelectorAll("turbo-frame");
    turboFrames.forEach((frame) => {
      frame.innerHTML = "";
    });
  });
}

function removeErrorClasses(element) {
  element.classList.forEach((className) => {
    if (className.includes("-error")) {
      element.classList.remove(className);
    }
  });

  Array.from(element.children).forEach((child) => {
    removeErrorClasses(child);
  });
}
